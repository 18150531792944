import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { userGuideLoadSuccess } from '@reducers/orm/user-guide/user-guide.action';
import { UserGuideModel } from '@reducers/orm/user-guide/user-guide.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppState } from '../../index';
import { UserGuideApi } from './user-guide.api';

@Injectable({ providedIn: 'root' })
export class UserGuideService {
  public isReady = new BehaviorSubject(false);

  public constructor(
    private api: UserGuideApi,
    private store: Store<AppState>,
  ) {}

  public getUserGuides(): Observable<UserGuideModel[]> {
    return this.api.fetch().pipe(
      tap((userGuides) => {
        this.store.dispatch(userGuideLoadSuccess({ userGuides }));
        this.isReady.next(true);
      }),
    );
  }

  public trackUserGuide(userGuide: UserGuideModel): Observable<void> {
    return this.api
      .track(userGuide)
      .pipe(tap((userGuide) => this.store.dispatch(userGuideLoadSuccess({ userGuides: [userGuide] }))));
  }
}

export enum UserGuideType {
  OVERTIME_SURCHARGE_MODAL = 'overtime-surcharge-modal',
  OVERTIME_SURCHARGE_BANNER = 'overtime-surcharge-banner',
  DEFAULT_SHIFT = 'default-shift',
  ABSENCE_POLICIES_BANNER = 'absence-policies-banner',
  DEPARTMENT_VARIATIONS_BANNER = 'department-variations-banner',
  ABSENCE_IN_DAYS_MODAL = 'absence-in-days-modal',
  ABSENCE_IN_DAYS_BANNER = 'absence-in-days-banner',
  QUICK_SCHEDULES_MODAL = 'quick-schedules-modal',
  QUICK_SCHEDULES_BANNER = 'quick-schedules-banner',
  MANAGER_ONBOARDING_CHECKLIST = 'manager-onboarding-checklist',
  SIGNUP_GREETING_MODAL = 'signup-greeting-modal',
  CONTRACT_TYPE_COC = 'contract-type-coc',
  TAUTOMATED_ABSENCE_CREATION_PUBLIC_HOLIDAYS = 'automated-absence-creation-public-holidays',
}

export enum UserGuideStatus {
  FINISHED = 'finished',
  DISMISSED = 'dismissed',
  IN_PROGRESS = 'in_progress',
}

export interface UserGuideModel {
  guide: string;
  step?: string;
  status: UserGuideStatus;
}

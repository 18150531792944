import { AppState } from '@app/reducers';
import { userGuideModelEntityAdapter, UserGuideState } from '@reducers/orm/user-guide/user-guide.state';
import { createSelector } from 'reselect';

import { UserGuideModel, UserGuideStatus, UserGuideType } from './user-guide.model';

export const getUserGuideState = (appState: AppState): UserGuideState => appState.orm.userGuides;

export const {
  selectAll,
  selectEntities: getUserGuideEntities,
  selectIds,
  selectTotal,
} = userGuideModelEntityAdapter.getSelectors(getUserGuideState);

export const selectUserGuideByType = (type: UserGuideType) =>
  createSelector(getUserGuideEntities, (userGuideEntities): UserGuideModel | undefined => userGuideEntities[type]);

export const isUserGuideFinished = (userGuideType: UserGuideType) =>
  createSelector(
    getUserGuideEntities,
    (userGuideEntities): boolean => userGuideEntities[userGuideType]?.status === UserGuideStatus.FINISHED,
  );

export const isUserGuideLoaded = createSelector(getUserGuideState, (state) => state.loaded);

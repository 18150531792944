import { Injectable } from '@angular/core';
import { UserGuideModel } from '@reducers/orm/user-guide/user-guide.model';

import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../../interfaces';

@Injectable({ providedIn: 'root' })
export class UserGuideApi {
  private endpoint = 'users/guides';

  public constructor(private gateway: ApiGateway) {}

  public fetch(dispatchStart?: Action) {
    return this.gateway.get(this.endpoint, undefined, dispatchStart);
  }

  public track(request: UserGuideModel, dispatchStart?: Action) {
    return this.gateway.post(this.endpoint, request, dispatchStart);
  }
}

import { createAction, props } from '@ngrx/store';
import { UserGuideModel } from '@reducers/orm/user-guide/user-guide.model';

const ACTION_PREFIX = '[UserGuide]';

export const userGuideLoadSuccess = createAction(`${ACTION_PREFIX} Load`, props<{ userGuides: UserGuideModel[] }>());

export const userGuideUpdateSuccess = createAction(
  `${ACTION_PREFIX} Update Success`,
  props<{ userGuide: UserGuideModel }>(),
);

export const userGuideUpdateRequest = createAction(
  `${ACTION_PREFIX} Update Request`,
  props<{ userGuide: UserGuideModel }>(),
);
